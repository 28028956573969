<template>
    <div>
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <v-col cols="5" lg="5" md="12" xs="12" class="px-1 pb-0 pt-3">
                    <v-row no-gutters>
                        <v-col cols="5" lg="5" md="3" xs="3">
                            <v-select
                                :items="searchFields"
                                :placeholder="$t('message.searchColumn')"
                                autocomplete="off"
                                clearable
                                prepend-icon="search"
                                dense
                                hide-details
                                solo
                                v-model="searchField"
                                @click:clear="resetSearch"
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="4" lg="4" md="3" xs="3" class="pl-2">
                            <v-text-field
                                :placeholder="$t('message.searchTerm')"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details
                                solo
                                v-model="searchTerm"
                                @keyup.enter="searchCoverNotes"
                                @click:clear="resetSearch"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="3" lg="3" md="3" xs="3" class="pl-2">
                            <v-row no-gutters>
                                <v-btn
                                    :loading="loading.search"
                                    class="col-6"
                                    @click="searchCoverNotes"
                                >{{ $t('message.search') }}</v-btn>
                                <v-btn
                                    :loading="loading.reset"
                                    class="col-6"
                                    @click="resetSearch"
                                >{{ $t('message.reset') }}</v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.coverNotes"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
				itemsPerPageOptions: [10,25,50,-1],
				showFirstLastPage: true
			}"
            :headers="headers"
            :hide-default-header="hideDefaultHeader"
            :hide-default-footer="hideDefaultFooter"
            :items="CoverNotes"
            :options.sync="tableOptions"
            :server-items-length="totalCoverNotes"
            calculate-widths
            class="mt-3 appic-table-light specification-table"
            dense
            id="coverNoteTable"
            item-key="CoverNote.id"
        >
<!--            <template v-slot:header="{ props: { headers } }">-->
<!--                <tr>-->
<!--                    <th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[0].text }}</th>-->
<!--                    <th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[1].text }}</th>-->
<!--                    <th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[2].text }}</th>-->
<!--                    <th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[3].text }}</th>-->
<!--                    <th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[4].text }}</th>-->
<!--                    <th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[5].text }}</th>-->
<!--                    <th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[6].text }}</th>-->
<!--                    <th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[7].text }}</th>-->
<!--                    <th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[8].text }}</th>-->
<!--                    <th class="grey lighten-3 pa-1 width-1-pct">{{ headers[9].text }}</th>-->
<!--                </tr>-->
<!--            </template>-->
            <template v-slot:item.CoverNote.title="{ item }">
                <span class="text-no-wrap font-weight-bold">{{ item.CoverNote.title }}</span>
            </template>
            <template v-slot:item.CoverNote.ttadate="{ item }">
                {{ item.CoverNote.ttadate != '0000-00-00' ? formatDate(item.CoverNote.ttadate) : '' }}
            </template>
            <template v-slot:item.CoverNote.Contract.title="{ item }">
                <span class="text-no-wrap font-weight-bold">{{ item.CoverNote.Contract.version != null ? [item.CoverNote.Contract.title, item.CoverNote.Contract.revision_no , item.CoverNote.Contract.partial_no].filter(Boolean).join('-') : item.CoverNote.Contract.title }}</span>
            </template>
            <template v-slot:item.CoverNote.Contract.etddate="{ item }">
                {{ item.CoverNote.Contract.etddate != '0000-00-00' ? formatDate(item.CoverNote.Contract.etddate) : '' }}
            </template>
            <template v-slot:item.CoverNote.Contract.etadate="{ item }">
                {{ item.CoverNote.Contract.etadate != '0000-00-00' ? formatDate(item.CoverNote.Contract.etadate) : '' }}
            </template>
            <template v-slot:item.CoverNote.Contract.shipmentstatus_text="{ item }">
                <v-icon small color="grey" class="mr-1" v-if="item.CoverNote.Contract.shipmentstatus_id == 6">fas fa-ship</v-icon>{{ item.CoverNote.Contract.shipmentstatus_id == 6 ? " " + item.CoverNote.Contract.shipmentstatus_text : "" }}
            </template>
            <template v-slot:item.CoverNote.Destination.name="{ item }">
                {{ item.CoverNote.Destinationport.title + " " + item.CoverNote.Destination.name }}
            </template>
            <template v-slot:item.CoverNote.Contract.containercount="{ item }">
                {{ item.CoverNote.Contract.containercount + ([4,7].includes(item.CoverNote.Contract.containersize_id) ? "" : " X ") + containerSizes.find(container => container.Containersize.id == item.CoverNote.Contract.containersize_id).Containersize.title  }}
            </template>
            <template v-slot:item.CoverNote.id="{ item }">
                <v-menu>
                    <template v-slot:activator="{ on: menu }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                    <v-icon>more_vert</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('message.moreActions') }}</span>
                        </v-tooltip>
                    </template>
                    <v-list dense>
                        <v-list-item class="font-sm" @click="updateCoverNote(item.CoverNote.id)"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateCoverNote') }}</v-list-item>
                        <v-list-item class="font-sm" @click="cancelCoverNote(item.CoverNote.id, item.CoverNote.title)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.cancelCoverNote') }}</v-list-item>
                        <v-list-item class="font-sm" @click="viewPdf(item.CoverNote.id)"><v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.pdf') }}</v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { formatDate } from "Helpers/helpers";
import SimpleAlert from "./SimpleAlert";
import { mapFields, mapMultiRowFields } from "vuex-map-fields";
import { mapActions,mapGetters } from "vuex";
import { v4 as uuidv4 } from 'uuid';

export default {
    name: "CoverNotesListing",
    components: {SimpleAlert},
    data() {
        return {
            dialogs: {
                error:  false,
                error_message: ""
            },
            filterOptions: {
                status: {
                    Status: {
                        id: 1,
                        title: 'open'
                    }
                }
            },
            hideDefaultHeader: false,
            hideDefaultFooter: false,
            loading: {
                filter: {
                    buyers: false
                },
                search: false,
                coverNotes: false
            },
            searchField: 'CoverNote.title', //default search field
            searchTerm: null,
            tableOptions: {},
        }
    },
    computed: {
        ...mapMultiRowFields('covernote', {
            CoverNotes: 'current.CoverNotes'
        }),
        ...mapFields('covernote', {
            totalCoverNotes: 'current.totalCoverNotes'
        }),
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        ...mapGetters([
            'containerSizes'
        ]),
        formatDate: () => formatDate,
        headers () {
            let headers = [
                {
                    id: 0,
                    text: this.$t('message.actions'),
                    value: 'CoverNote.id',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                    searchable: false,
                    sortable: false
                },
                {
                    id: 1,
                    text: this.$t('message.coverNote'),
                    value: 'CoverNote.title',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: true,
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.date'),
                    value: 'CoverNote.ttadate',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: false
                },
                {
                    id: 3,
                    text: this.$t('message.forContract'),
                    value: 'CoverNote.Contract.title',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: true,
                    sortable: false
                },
                {
                    id: 4,
                    text: this.$t('message.buyer'),
                    value: 'CoverNote.Customer.title',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: true,
                    sortable: false
                },
                {
                    id: 5,
                    text: this.$t('message.etd'),
                    value: 'CoverNote.Contract.etddate',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: false
                },
                {
                    id: 6,
                    text: this.$t('message.eta'),
                    value: 'CoverNote.Contract.etadate',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: false
                },
                {
                    id: 7,
                    text: this.$t('message.vessel'),
                    value: 'CoverNote.Contract.shipmentstatus_text',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: false
                },
                {
                    id: 8,
                    text: this.$t('message.destination'),
                    value: 'CoverNote.Destination.name',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: false
                },
                {
                    id: 9,
                    text: this.$t('message.containers'),
                    value: 'CoverNote.Contract.containercount',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: false
                }
            ]
            return headers
        },
        searchFields() {
            return this.headers.filter((header)=>header.searchable === true)
        },
    },
    methods: {
        ...mapActions('covernote', {
            cancelCoverNoteById: 'cancelCoverNoteById',
            getAllCoverNotes: 'getAllCoverNotes',
            searchAllCoverNotes: 'searchAllCoverNotes'
        }),
        async cancelCoverNote (val, title) {
            if(await this.$root.$confirm(this.$t('message.cancelCoverNote') + ' ' + (title != null ? title : ''), this.$t('message.confirmations.continueCoverNoteCancelAction'), {color: 'orange'})){
                this.cancelLcaById(val).then((status) => {
                    if(status == 'done'){
                        this.$toast.success(this.$t('message.successes.lcaDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                        this.loadLcas()
                    } else {
                        this.$toast.error(this.$t('message.errors.lcaNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                })
                    .catch(()=>{
                        this.$toast.error(this.$t('message.errors.lcaNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
                    .finally(()=>{

                    })
            }
        },
        dialogClosed () {
            this.dialogs.error = false;
            this.dialogs.error_message = ''
        },
        loadCoverNotes () {
            this.loading.coverNotes = true;
            let payload = {
                tableOptions: this.tableOptions,
                filterOptions: this.filterOptions
            }
            this.getAllCoverNotes(payload)
                .then(()=>{
                    this.loading.coverNotes = false;
                })
                .catch(()=>{
                    this.loading.coverNotes = false;
                })
                .finally(()=>{
                    this.loading.coverNotes = false;
                })
        },
        resetSearch () {
            this.hideDefaultFooter = false
            this.loading.coverNotes = false
            this.searchField = null;
            this.searchTerm = null;
            this.tableOptions.itemsPerPage = 10
            this.filterOptions = {};
        },
        searchCoverNotes () {
            if(this.searchField == null || this.searchTerm == null){
                this.dialogs.error = true
                this.dialogs.error_message = this.$t('message.errors.noSearchColumnAndSearchTerm')
            } else {
                //TODO search cover notes
                this.filterOptions = {}
                this.hideDefaultFooter = true
                this.loading.ipas = true;
                this.loading.search = true;
                let payload = {
                    tableOptions: this.tableOptions,
                    search: {
                        field: this.searchField,
                        value: this.searchTerm
                    }
                }
                this.searchAllCoverNotes(payload)
                    .then(() => {
                        this.loading.search = false;
                        this.loading.coverNotes = false;
                    })
                    .catch(()=>{
                        this.loading.search = false;
                        this.loading.coverNotes = false;
                    })
                    .finally(()=>{
                        this.loading.search = false;
                        this.loading.coverNotes = false;
                    })
            }
        },
        updateCoverNote (val) {
            let tab = window.open('/v1/covernotes/update/' + val,'_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
            // router.push({name: 'update_covernote', params: { covernoteId : val}}).catch(err => {})
        },
        viewPdf (covernote_id) {
            let document = encodeURIComponent('CN ' + (this.CoverNotes.find( cn => cn.CoverNote.id == covernote_id)?.CoverNote?.title))
            let user = JSON.parse(localStorage.getItem('user'))
            let tab = window.open(
                process.env.VUE_APP_PDF_SERVER
                + '/api/render/'
                + document
                + '?url='
                + process.env.VUE_APP_BASE_URL
                + '/v1/covernotes/print/'
                + covernote_id
                + '/'
                + uuidv4()
                + '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                , "_blank");
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        }
    },
    watch: {
        filterOptions: {
            handler(){
                if(!this.loading.search) {
                    this.loadCoverNotes()
                }
            },
            deep: true
        },
        tableOptions: {
            handler(){
                this.loadCoverNotes()
            },
            deep: true
        },
    },
    created(){
        let dt = new Date()
        let defaultYear = dt.getFullYear() - 1
        this.loadCoverNotes()
    }
}
</script>

<style>
.font-xs {
    font-size: 0.60rem;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
</style>